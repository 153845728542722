/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
import React, {
    useCallback, useEffect, useRef, useState,
} from 'react';
import {
    any, object, func, string,
} from 'prop-types';
import mbpUtil from 'mbp-api-util';

const ConversationalUIChatBot = ({
    deviceType, brand, browser, operatingSystem, brandTheme,
}) => {
    const iframeRef = useRef(null);
    const [isIframeMaximized, setIsIframeMaximized] = useState(false);

    const isMobile = deviceType !== 'desktop';
    const [isIframeLoaded, setIsIframeLoaded] = useState(true);
    const trustedOrigin = `${mbpUtil.getEnv('APP_CONVERSATIONAL_UI_HOST')}`;

    useEffect(() => {
        if (isIframeMaximized) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = '';
        }
    }, [isIframeMaximized]);
    useEffect(() => {
        const handleMessage = (event) => {
            if (event.data.type === 'conv-ui-session-info') {
                const url = window.location.href;
                const auth = JSON.parse(JSON.parse(sessionStorage.getItem('persist:member'))?.auth);
                const profile = JSON.parse(JSON.parse(sessionStorage.getItem('persist:member'))?.profile);
                const enterprise = JSON.parse(JSON.parse(sessionStorage.getItem('persist:member'))?.enterpriseId);
                const browseruuid = localStorage?.getItem('browserUUID') || '';
                event.source.postMessage({
                    type: 'session-storage-data',
                    data: {
                        url,
                        brand,
                        browser,
                        deviceType,
                        operatingSystem,
                        brandTheme,
                        auth,
                        profile,
                        enterprise,
                        browseruuid,
                    },
                }, `${trustedOrigin}/chat`);
            }
            if (event.data.type === 'search-suggestion-submitted') {
                const iframe = document?.querySelector(`iframe[src="${mbpUtil.getEnv('APP_CONVERSATIONAL_UI_HOST')}/chat"]`);
                if (iframe && iframe?.contentWindow) {
                    iframe.contentWindow?.postMessage(
                        { type: 'update-chat-message-search', data: event.data },
                        mbpUtil.getEnv('APP_CONVERSATIONAL_UI_HOST'),
                    );
                }
            }
            if (event.data.type === 'footer-suggestion-submitted') {
                const iframe = document?.querySelector(`iframe[src="${mbpUtil.getEnv('APP_CONVERSATIONAL_UI_HOST')}/chat"]`);
                if (iframe && iframe?.contentWindow) {
                    iframe.contentWindow.postMessage(
                        { type: 'update-chat-message-footer', data: event.data },
                        mbpUtil.getEnv('APP_CONVERSATIONAL_UI_HOST'),
                    );
                }
            }
            if (event.data.type === 'chat-iframe-maximised') {
                const headerElement = document?.getElementById('global_header_container');
                if (isMobile) {
                    setIsIframeMaximized(() => true);
                    iframeRef.current.style.height = '100vh';
                    iframeRef.current.style.width = '100vw';
                    iframeRef.current.style.right = '0px';
                    // dynamic top for iframe based on headerElement height
                    iframeRef.current.style.top = `${headerElement?.clientHeight}px` || '54px';
                } else {
                    iframeRef.current.style.height = '560px';
                    iframeRef.current.style.width = '400px';
                }
            }
            if (event.data.type === 'chat-iframe-minimised') {
                setIsIframeMaximized(() => false);
                iframeRef.current.style.height = '80px';
                iframeRef.current.style.width = '80px';
                iframeRef.current.style.top = '';
                iframeRef.current.style.bottom = '10px';
            }
        };

        if (isIframeLoaded) {
            window?.addEventListener('message', handleMessage);
        }
        return () => {
            window?.removeEventListener('message', handleMessage);
        };
    }, [isIframeLoaded, trustedOrigin]);

    const handleIframeLoad = () => setIsIframeLoaded(true);
    const handleIframeError = () => setIsIframeLoaded(false);

    return isIframeLoaded ? (
        <iframe
            id="18fchatbot"
            ref={iframeRef}
            title="18fchatbot"
            className="test"
            allowFullScreen
            style={{
                overflow: 'hidden',
                boxShadow: 'none',
                border: 'none',
                position: 'fixed',
                zIndex: 2243000,
                bottom: '10px',
                right: '20px',
                width: '80px',
                height: '80px',
            }}
            src={`${trustedOrigin}/chat`}
            onLoad={handleIframeLoad}
            onError={handleIframeError}
        />
    ) : null;
};

ConversationalUIChatBot.propTypes = {
    deviceType: string.isRequired,
    brand: object.isRequired,
    browser: func.isRequired,
    operatingSystem: func.isRequired,
    brandTheme: any.isRequired,
};

export default ConversationalUIChatBot;
