/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import mbpLogger from 'mbp-logger';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { getFeatureFlag } from '../../../../state/ducks/App/ducks/Config/Config-Selectors';
import { getIsBot } from '../../../../state/ducks/App/App-Selectors';
import useSalesforcePersonalizedContentQuery from '../../../helpers/Personalization/useSalesforcePersonalizedContentQuery';
import useClickStreamCustomEventsTracking from '../../../helpers/Personalization/useClickstreamCustomEventsTracking';
import { getCurrentPageType } from '../../../../state/ducks/TagManager/ducks/TagManager/TagManager-Selectors';
import GenericSkeleton from '../GraphqlSkeletonComponents/GenericSkeleton';
import ConnectedSearch from './Search';
import SearchV2 from './SearchV2';

const useStyles = makeStyles((theme) => ({
    searchSkeleton: {
        height: '38px',
        width: '100%',
        maxWidth: '727px',
        margin: '15px 0px 15px 40px',
        borderRadius: '3px',
        [theme.breakpoints.down(1026)]: {
            margin: '0 8px',
            width: 'auto',
            maxWidth: 'unset',
        },
    },
    mobileViewSkeleton: {
        paddingBottom: '8px',
    },
}));

function SearchBlock({
    typeSearchBox, brand, trackClickThrough, focusOnShow, hideHeaderOnScroll,
}) {
    const classes = useStyles();
    const isSearchBarBrandFilterEnabled = useSelector(getFeatureFlag('is-search-bar-brand-filter-enabled'));
    const isBot = useSelector(getIsBot);
    const pageType = useSelector(getCurrentPageType);

    if (!isSearchBarBrandFilterEnabled || isBot) {
        return (
            <ConnectedSearch
                focusOnShow={focusOnShow}
                brand={brand}
                typeSearchBox={typeSearchBox}
                trackClickThrough={trackClickThrough}
            />
        );
    }

    const skeleton = (id) => {
        if (typeSearchBox === 'header') {
            return (
                <div className={classes.mobileViewSkeleton}>
                    <GenericSkeleton className={classes.searchSkeleton} id="id" />
                </div>
            );
        }
        return <GenericSkeleton className={classes.searchSkeleton} id={id} />;
    };

    if (isSearchBarBrandFilterEnabled && typeof window === 'undefined') {
        return skeleton('searchBarV2Skeleton');
    }

    const {
        error, data, loading, variables,
    } = useSalesforcePersonalizedContentQuery({
        queryName: 'searchBarBrandFilter',
        interactionName: `${brand.domain} - Get Campaign - Search Bar Brand Filter`,
        featureFlag: isSearchBarBrandFilterEnabled,
        optInHoldoutParticipation: false,
        resolveIdentity: false,
        apolloOptions: {
            fetchPolicy: 'cache-first',
        },
    });

    const campaignTrackClickThrough = useClickStreamCustomEventsTracking({
        salesforceResponse: data?.contentSF,
        isFireImpression: true,
        page: { type: pageType },
    });

    const trackAllClickThrough = () => {
        if (trackClickThrough) trackClickThrough();
        campaignTrackClickThrough();
    };

    if (loading || variables?.skip) {
        return skeleton('searchBarV2LoadingSkeleton');
    }

    if (error) {
        mbpLogger.logError({
            function: 'SearchV2',
            message: 'Error loading API data',
            appName: process.env.npm_package_name,
            module: 'PersonalizedContent',
            jsError: error,
        });
    }

    const userGroup = data?.contentSF?.campaign?.campaignResponses?.[0]?.payload?.userGroup;

    if (userGroup === 'Test') {
        return (
            <SearchV2
                brand={brand}
                typeSearchBox={typeSearchBox}
                trackClickThrough={campaignTrackClickThrough}
                hideHeaderOnScroll={hideHeaderOnScroll}
            />
        );
    }

    return (
        <ConnectedSearch
            focusOnShow={focusOnShow}
            brand={brand}
            typeSearchBox={typeSearchBox}
            trackClickThrough={trackAllClickThrough}
        />
    );
}

SearchBlock.propTypes = {
    typeSearchBox: PropTypes.string.isRequired,
    brand: PropTypes.object.isRequired,
    trackClickThrough: PropTypes.func,
    focusOnShow: PropTypes.bool,
    hideHeaderOnScroll: PropTypes.func,
};

SearchBlock.defaultProps = {
    trackClickThrough: () => {},
    focusOnShow: false,
    hideHeaderOnScroll: () => {},
};

export default SearchBlock;
