/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import {
    object, string, shape,
} from 'prop-types';
import { useDispatch } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import ReactMarkdown from 'react-markdown';
import { makeStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { trackEvent } from '../../../../state/ducks/TagManager/ducks/TagManager/TagManager-Actions';
import LinkBlockStyle from './LinkBlockStyle';
import StyledButton from '../../EnterpriseDesignSystem/Button/StyledButton';

const useStyles = makeStyles((theme) => LinkBlockStyle(theme));

const LinkBlock = ({
    data, commonClasses,
}) => {
    if (!data) return null;
    const classes = useStyles();
    const heading = (data.heading || '');
    const message = (data.message || '');
    const link = (data.link || {});
    const dispatch = useDispatch();

    return (
        <Grid container className={classes.boxLayoutBorder} role="region" aria-label={heading}>
            <Grid item md={9} xs={12}>
                {heading && <Typography variant="h6" gutterBottom>{heading}</Typography>}
                <Grid item md={12} xs={12}>
                    <ReactMarkdown source={message} className={commonClasses.markdown} />
                </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={3} className={commonClasses.buttonCenter}>
                <Grid container direction="row" justify="flex-end" alignItems="center">
                    <Grid item xs={12}>
                        <StyledButton
                            variant="primary"
                            size="md"
                            label={link.title}
                            link={link.href || ''}
                            handleOnClick={() => {
                                dispatch(trackEvent({
                                    eventCategory: 'Content Page Block',
                                    eventAction: 'Button Click',
                                    eventLabel: `${link.title}`,
                                }));
                            }}
                            attributes={{ id: `link-block-${link.title.split(' ').join('-').toLowerCase()}`, 'aria-labeledby': `link-block-label${link.title.split(' ').join('-').toLowerCase()}` }}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

LinkBlock.propTypes = {
    commonClasses: object.isRequired,
    data: shape({
        heading: string.isRequired,
        message: string.isRequired,
        link: shape({
            href: string.isRequired,
            title: string.isRequired,
        }).isRequired,
    }).isRequired,
};

export default LinkBlock;
