/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import {
    array, bool, func, string,
} from 'prop-types';
import { useDispatch } from 'react-redux';
import useStyles from 'isomorphic-style-loader/useStyles';

import styles from './styles/OfferGatewayButton.module.css';
import StyledButton from '../../../../EnterpriseDesignSystem/Button/StyledButton';
import { setDialogOpen, setDialogType, setOfferGatewayCTALabel } from '../../../../../../state/ducks/App/App-Actions';
import replaceSpecialChar from '../../../../../helpers/replaceSpecialChar';
import noop from '../../../../../helpers/noop';

export default function OfferGatewayButton({
    ctaArray, buttonContainer, clickCallback,
}) {
    const dispatch = useDispatch();
    useStyles(styles);

    const handleClick = (event, item) => {
        if (item.launch_modal) {
            event.stopPropagation();
            event.preventDefault();
            dispatch(setDialogOpen(true));
            dispatch(setDialogType('offerGateway'));
            dispatch(setOfferGatewayCTALabel(item.tracking.tracking_event_label));
        }
        clickCallback();
    };

    const renderHelperText = (item) => {
        if (item.is_helper_text) {
            return <p className="text2"> {replaceSpecialChar(item.helper_text, true)}</p>;
        }
        return <></>;
    };

    return ctaArray?.map((item) => (
        <div className={`${buttonContainer} ${styles.marginTop}`}>
            {renderHelperText(item)}
            <StyledButton
                label={item.cta_button.link.title}
                link={item.cta_button.link.href}
                variant={item.cta_button.variant}
                size={item.cta_button.size}
                attributes={item.cta_button?.html_attributes}
                handleOnClick={(event) => handleClick(event, item.cta_button)}
                icon={item?.icon}
            />
        </div>
    ));
}

OfferGatewayButton.propTypes = {
    ctaArray: array.isRequired,
    buttonContainer: string.isRequired,
    isOfferUnlocked: bool.isRequired,
    clickCallback: func,
};

OfferGatewayButton.defaultProps = {
    clickCallback: noop,
};
