/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import { func, object } from 'prop-types';
import React, { useEffect } from 'react';
import mbpLogger from 'mbp-logger';
import useStyles from 'isomorphic-style-loader/useStyles';

import styles from '../../../UniversalTemplate/Components/Banners/OneUp/Standard/Tall/Tall.module.css';
import ComponentErrorBoundary from '../../../EnterpriseID/Common/ComponentErrorBoundary/ComponentErrorBoundary';
import LinkListBanner from '../../../UniversalTemplate/SubComponents/LinkListBanner/LinkListBanner';
import ResponsiveWrapper from '../../../UniversalTemplate/SubComponents/ResponsiveWrapper';
import OfferGatewayMessageStandard from './OfferGatewayMessageStandard';
import useClickStreamCustomEventsTracking from '../../../../../helpers/Personalization/useClickstreamCustomEventsTracking';

export default function OfferGatewayBannerTall({
    blockObj, salesforceResponse, setShowSkeleton,
}) {
    useStyles(styles);
    if (!Object.keys(blockObj || {})?.length) {
        mbpLogger.logError({
            appName: process.env.npm_package_name,
            component: 'OfferGatewayBannerTall.js',
            message: 'No data available to render component',
        });
        return <></>;
    }

    const isBackground = blockObj?.message?.has_background;
    const linkListPosition = blockObj?.link_list?.[0]?.link_list?.position || 'row';
    const backgrounds = {
        desktop: blockObj.background_image?.desktop?.url,
        tablet: blockObj.background_image.tablet?.url || blockObj.background_image?.desktop?.url, // can add fallbacks easily this way
        mobile: blockObj.background_image.mobile?.url || blockObj.background_image.tablet?.url || blockObj.background_image?.desktop?.url,
    };

    useClickStreamCustomEventsTracking({
        salesforceResponse,
        isFireImpression: true,
        page: { type: 'home' },
    });

    useEffect(() => {
        setShowSkeleton(false);
    }, []);

    const device = (matches) => Object.keys(matches).find((key) => matches[key]); // returns device type string

    const renderLinkList = (matches, deviceArray) => {
        if (blockObj.has_link_list && deviceArray.includes(device(matches))) return <LinkListBanner data={blockObj.link_list[0]} />;
        return <></>;
    };

    const imageQuality = '?auto=webp';

    // UI examples: https://1800flowersinc.atlassian.net/wiki/spaces/CMS/pages/2437283850/Banners#Tall
    // This component is a replica of Banner1UpTallStandard from /UniversalTemplate/Components/Banners/OneUp/Standard/Tall/Tall.js
    // with some minor changes to fit the OfferGateway use case

    const renderBanner = (matches) => (
        <>
            <div style={{ backgroundImage: `url(${backgrounds[device(matches)]}${imageQuality})`, flexDirection: linkListPosition }} className={`${styles.banner} ${blockObj?.has_link_list ? styles.wrapperContainer : ''}`}>
                {Object.keys(blockObj.message)?.length > 0 && (blockObj?.link_list?.length > 0 && (isBackground || !isBackground)) && <div className={`${styles.messageBlock} ${isBackground ? styles.messageBg : ''}`}><OfferGatewayMessageStandard salesforceResponse={salesforceResponse} data={blockObj.message} /></div>}
                {!isBackground && !blockObj?.link_list?.length && <div className={`${styles.messageBlock} ${isBackground ? styles.messageBg : ''}`}><OfferGatewayMessageStandard salesforceResponse={salesforceResponse} data={blockObj.message} /></div>}
                {(device(matches) === 'desktop' || device(matches) === 'tablet') && isBackground && !blockObj?.link_list?.length && <div className={`${styles.messageBlock} ${isBackground ? styles.messageBg : ''}`}><OfferGatewayMessageStandard salesforceResponse={salesforceResponse} data={blockObj.message} /></div>}

                {renderLinkList(matches, ['desktop'])}
            </div>
            {device(matches) === 'mobile' && isBackground && !blockObj?.link_list?.length && <div className={`${styles.messageBlock} ${isBackground ? styles.messageBg : ''}`}><OfferGatewayMessageStandard salesforceResponse={salesforceResponse} data={blockObj.message} /></div>}

            {renderLinkList(matches, ['tablet', 'mobile'])}

            {backgrounds[device(matches)] && (
                <picture>
                    <source srcSet={`${backgrounds[device(matches)]}${imageQuality}`} />
                    <img src={`${backgrounds[device(matches)]}${imageQuality}`} alt="background image" height="0" width="0" className={styles.lcpImage} loading="lazy" />
                </picture>
            )}
        </>
    );
    const bannerLink = blockObj.message?.custom_button?.[0]?.cta_button;

    return (
        <ComponentErrorBoundary module="OfferGateway.BannerTall">
            <div data-testid="tallBanner" className={`${bannerLink?.href && bannerLink?.title ? styles.bannerContainer : ''} ${isBackground && blockObj?.has_link_list ? styles.container : ''}`} role="presentation">
                <ResponsiveWrapper renderer={renderBanner} />
            </div>
        </ComponentErrorBoundary>
    );
}

OfferGatewayBannerTall.propTypes = {
    blockObj: object.isRequired,
    salesforceResponse: object.isRequired,
    setShowSkeleton: func.isRequired,
};
